import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PageNavigation,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  SectionSubhead,
  AccessibilityLabelPartial,
  TestIdPartial,
  SpacePartial,
  IsReadOnlyPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const multiSnippet = require('raw-loader!../../../../data/snippets/rn-lookup-multi-select.example');
const lookupSnippet = require('raw-loader!../../../../data/snippets/rn-lookup-select.example');
const singleSnippet = require('raw-loader!../../../../data/snippets/rn-single-select.example');

const multiSelectListSnippet = require('raw-loader!../../../../data/snippets/rn-multi-select-list.example');
const selectListSnippet = require('raw-loader!../../../../data/snippets/rn-select-list.example');
const selectPickerSnippet = require('raw-loader!../../../../data/snippets/rn-select-picker.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation
        links={[
          'Single Select',
          'Select Picker',
          'Lookup Select',
          'Select List',
          'Lookup Multi Select',
          'Multi Select List',
        ]}
      />
      <Section title="Single Select">
        <CodeSnippet
          disableCodeEditing
          code={singleSnippet}
          platform="react"
          gitHubLink="selects/single-select"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="hasError" types={['boolean']}>
              <Text>
                Determines whether to show an error state (red text and border)
                on the input.
              </Text>
            </PropListItem>

            <PropListItem name="helpText" types={['string']}>
              <Text>
                The text that appears below the select, usually instructing the
                user on what to provide.
              </Text>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>Determines whether the select is disabled.</Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="select" />

            <PropListItem name="isRequired" types={['boolean']}>
              <Text>
                Determines whether the required text appears next to the label.
              </Text>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>The text that appears above the select.</Text>
            </PropListItem>

            <PropListItem name="placeholder" types={['string']}>
              <Text>
                Placeholder text of the select component when an option isn't
                selected.
              </Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the size of the input.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <SpacePartial />
          </PropList>

          <PropList header="Functional">
            <PropListItem name="value" types={['string']}>
              <Text>Determines what is shown in the select input.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['() => void']} isRequired>
              <Text>
                Responds to the select being tapped. This is where you'd show
                the select item view.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="single select" />

            <TestIdPartial componentName="single select" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>

          <SectionSubhead>Notes</SectionSubhead>
          <List type="unordered">
            <li>
              To show an error message, simply pass in <code>hasError</code> and
              put the error text in the <code>helpText</code> attribute.
            </li>
            <li>
              You can show any view of the select items you want, but if you
              want an easy default, one is provided below.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Select"
        />
      </Section>

      <Section title="Select Picker">
        <CodeSnippet
          code={selectPickerSnippet}
          platform="react-native"
          gitHubLink="selects/select-picker"
          disableCodeEditing
        />
        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="isVisible" types={['boolean']}>
              <Text>Determines whether the view is visible.</Text>
            </PropListItem>

            <PropListItem name="items" types={['ListItemData[]']} isRequired>
              <Text>
                The list of items to show. Items need the following:
                <List type="unordered">
                  <li>
                    <code>value</code>
                  </li>
                  <li>
                    <code>label</code>
                  </li>
                  <li>
                    <code>key</code>
                  </li>
                </List>
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Functional">
            <PropListItem name="value" types={['string']}>
              <Text>Determines which item is currently selected.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onDismiss" types={['() => void']} isRequired>
              <Text>
                Responds to the picker being dismissed. This is where you'd hide
                the select list view.
              </Text>
            </PropListItem>

            <PropListItem
              name="onValueChange"
              types={['(value: string) => void']}
              isRequired>
              <Text>
                Responds to the picker value changing. This is where you'd
                update the value.
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>

      <Section title="Lookup Select">
        <CodeSnippet
          disableCodeEditing
          code={lookupSnippet}
          platform="react-native"
          gitHubLink="selects/lookup-select"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="hasError" types={['boolean']}>
              <Text>
                Determines whether to show an error state (red text and border)
                on the input.
              </Text>
            </PropListItem>

            <PropListItem name="helpText" types={['string']}>
              <Text>
                The text that appears below the select, usually instructing the
                user on what to provide.
              </Text>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>Determines whether the select is disabled.</Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="select" />

            <PropListItem name="isRequired" types={['boolean']}>
              <Text>
                Determines whether the required text appears next to the label.
              </Text>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>The text that appears above the select.</Text>
            </PropListItem>

            <PropListItem name="placeholder" types={['string']}>
              <Text>
                Placeholder text of the select component when an option isn't
                selected.
              </Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the size of the input.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <SpacePartial />
          </PropList>

          <PropList header="Functional">
            <PropListItem name="value" types={['string']}>
              <Text>Determines what is shown in the select input.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['() => void']} isRequired>
              <Text>
                Responds to the select being tapped. This is where you'd show
                the select item view.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="select" />

            <TestIdPartial componentName="select" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>

          <SectionSubhead>Notes</SectionSubhead>
          <List type="unordered">
            <li>
              To show an error message, simply pass in <code>hasError</code> and
              put the error text in the <code>helpText</code> attribute.
            </li>
            <li>
              You can show any view of the select items you want, but if you
              want an easy default, one is provided below.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Lookup Select"
        />
      </Section>

      <Section title="Select List">
        <CodeSnippet
          code={selectListSnippet}
          platform="react-native"
          gitHubLink="selects/select-list"
          disableCodeEditing
        />
        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="isVisible" types={['boolean']}>
              <Text>Determines whether the view is visible.</Text>
            </PropListItem>

            <PropListItem name="items" types={['ListItemData[]']}>
              <Text>
                The list of items to show. Items need the following:
                <List type="unordered">
                  <li>
                    <code>value</code>
                  </li>
                  <li>
                    <code>label</code>
                  </li>
                  <li>
                    <code>key</code>
                  </li>
                </List>
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Functional">
            <PropListItem name="value" types={['string']}>
              <Text>Determines which item is currently selected.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onInputChange"
              types={['(inputText: string) => void']}>
              <Text>
                Responds to the search input being changed. This is where you'd
                filter your items. Provides a string back of the text in the
                input.
              </Text>
            </PropListItem>

            <PropListItem
              name="onSelect"
              types={['(value: string, label: string) => void']}
              isRequired>
              <Text>
                Responds to the select item being tapped. Provides the label and
                value of the item back.
              </Text>
            </PropListItem>

            <PropListItem name="onDismiss" types={['() => void']} isRequired>
              <Text>
                Responds to the select list being dismissed. This is where you'd
                hide the select list view.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from{' '}
                <Link href="https://facebook.github.io/react-native/docs/flatlist.html">
                  React Native's FlatList
                </Link>
                .
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>

      <Section title="Lookup Multi Select">
        <CodeSnippet
          disableCodeEditing
          code={multiSnippet}
          platform="react"
          gitHubLink="selects/lookup-multi-select"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="hasError" types={['boolean']}>
              <Text>
                Determines whether to show an error state (red text and border)
                on the input.
              </Text>
            </PropListItem>

            <PropListItem name="helpText" types={['string']}>
              <Text>
                The text that appears below the select, usually instructing the
                user on what to provide.
              </Text>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>Determines whether the select is disabled.</Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="select" />

            <PropListItem name="isRequired" types={['boolean']}>
              <Text>
                Determines whether the required text appears next to the label.
              </Text>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>The text that appears above the select.</Text>
            </PropListItem>

            <PropListItem name="placeholder" types={['string']}>
              <Text>
                Placeholder text of the select component when an option isn't
                selected.
              </Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the size of the input.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <SpacePartial />
          </PropList>

          <PropList header="Functional">
            <PropListItem name="values" types={['string[]']}>
              <Text>
                Determines what is shown in the select input. Provide an array
                in order to show more than one item as selected.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['() => void']}>
              <Text>
                Responds to the select being tapped. This is where you'd show
                the select item view.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="lookup multi select" />
            TestIdPartial componentName="lookup multi select" />
          </PropList>

          <SectionSubhead>Notes</SectionSubhead>
          <List type="unordered">
            <li>
              To show an error message, simply pass in <code>hasError</code> and
              put the error text in the <code>helpText</code> attribute.
            </li>
            <li>
              You can show any view of the select items you want, but if you
              want an easy default, one is provided below.
            </li>
          </List>
        </Section>
      </Section>

      <Section title="Multi Select List">
        <CodeSnippet
          code={multiSelectListSnippet}
          platform="react-native"
          gitHubLink="selects/multi-select-list"
          disableCodeEditing
        />
        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="isVisible" types={['boolean']}>
              <Text>Determines whether the view is visible.</Text>
            </PropListItem>

            <PropListItem name="items" types={['ListItemData[]']} isRequired>
              <Text>
                The list of items to show. Items need the following:
                <List type="unordered">
                  <li>
                    <code>value</code>
                  </li>
                  <li>
                    <code>label</code>
                  </li>
                  <li>
                    <code>key</code>
                  </li>
                </List>
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Functional">
            <PropListItem name="values" types={['string[]']}>
              <Text>Determines which items are currently selected.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onInputChange"
              types={['(inputText: string) => void']}>
              <Text>
                Responds to the search input being changed. This is where you'd
                filter your items. Provides a string back of the text in the
                input.
              </Text>
            </PropListItem>

            <PropListItem
              name="onSelect"
              types={['(value: string, label: string) => void']}
              isRequired>
              <Text>
                Responds to the select item being tapped. Provides the label and
                value of the item back. This is where you'd add or remove the
                value from the array of values.
              </Text>
            </PropListItem>

            <PropListItem name="onDismiss" types={['() => void']} isRequired>
              <Text>
                Responds to the multi select list being dismissed. This is where
                you'd hide the select list view.
              </Text>
            </PropListItem>

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from{' '}
                <Link href="https://facebook.github.io/react-native/docs/flatlist.html">
                  React Native's FlatList
                </Link>
                .
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
