import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  HasErrorPartial,
  HelpTextPartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  Link,
  List,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const CreatableSelect = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CreatableSelect;
    return <Component {...props} />;
  },
});

const Select = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Select;
    return <Component {...props} />;
  },
});

const selectSnippet = require('raw-loader!../../../../data/snippets/select.example');
const creatableSelectSnippet = require('raw-loader!../../../../data/snippets/creatable-select.example');
// eslint-disable-next-line max-len
const creatableSelectTypeaheadSnippet = require('raw-loader!../../../../data/snippets/creatable-select-typeahead.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Select', 'Creatable Select']} />
      <V5Notice
        code
        componentName="Select and Creatable Select"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-selects--docs"
        additionalContent={
          <span>
            We upgraded the <code>react-select</code> version that powers the
            Uniform Selects. The API changes are lightweight, and more
            information can be found in their{' '}
            <Link href="https://react-select.com/upgrade" isExternal>
              upgrade guides
            </Link>
            .
          </span>
        }
      />
      <Section title="Select">
        <CodeSnippet
          scope={{ React: React, Select: Select }}
          code={selectSnippet}
          platform="react"
          gitHubLink="forms/select"
          id="select-example"
        />

        <Section title="Props">
          <PropList header="Visual">
            <ClassnamePartial componentName="select" />

            <EnvironmentPartial />

            <FormSizePartial componentName="select" />

            <HasErrorPartial componentName="select" />

            <HelpTextPartial componentName="select" />

            <IsDisabledPartial componentName="select" />

            <IsReadOnlyPartial componentName="select" />

            <IsRequiredPartial componentName="select" />

            <PropListItem name="label" types={['string']}>
              <Text>The text that appears above the select.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Functional">
            <PropListItem name="includeValueInSearch" types={['boolean']}>
              Will include option values when searching. Defaults to{' '}
              <code>false</code>.
            </PropListItem>

            <PropListItem name="isMulti" types={['boolean']}>
              Turns on the ability to select one more than one option.
            </PropListItem>

            <PropListItem name="isSearchable" types={['boolean']}>
              Turns the lookup functionality on.
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onGroupHeaderClick"
              types={['(value: string) => void']}>
              <Text>
                A unique string that appears as a data attribute in the rendered
                code, serving as a hook for automated tests.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial
              componentName="select"
              passedDown={['select', 'value-option (for each option)']}
            />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from the{' '}
                <Link href="https://react-select.com/">React-Select</Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              To show an error message, simply pass in <code>hasError</code> and
              put the error text in the <code>helpText</code> attribute.
            </li>
            <li>
              When adding a select to something that is portaled (like a modal),
              add the <code>menuPortalTarget</code> with a reference to the dom
              node (such as document.body) so the select is correctly rendered.
            </li>
            <li>
              Native <code>&lt;select /&gt;</code> and{' '}
              <code>&lt;option /&gt;</code> elements are not rendered.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Select"
        />
      </Section>

      <Section title="Creatable Select">
        <CodeSnippet
          scope={{ React: React, CreatableSelect: CreatableSelect }}
          code={creatableSelectSnippet}
          platform="react"
          gitHubLink="forms/creatable-select"
          id="creatable-snippet"
        />

        <Paragraph>
          The Creatable Select allows the user to add an option not shown in the
          list. It shares a similar API to the Select above, but also comes with
          it's own set of{' '}
          <Link href="https://react-select.com/props#creatable-props">
            props
          </Link>
          .
        </Paragraph>
        <PropList header="Creatable Select Props">
          <PropListItem name="hasSingleError" types={['boolean']}>
            <Text>
              Determines whether to apply error styling to the select when some
              selected options have error.
            </Text>
          </PropListItem>
        </PropList>

        <SectionSubhead>
          Adding validation to individual options (pills)
        </SectionSubhead>
        <Paragraph>
          If validating individual options added in a creatable select is
          needed, e.g. to ensure that email addresses are valid, there are a few
          things to understand:
        </Paragraph>
        <List style="unordered">
          <li>
            The <code>value</code> prop should be used to update the currently
            selected options after validation. The <code>hasError</code>{' '}
            property needs to be set on the object within the value array that
            corresponds to an option with an error.{' '}
          </li>
          <li>
            The <code>handleChange</code> example below shows how to check the
            values when one is added, removed or updated and properly apply the{' '}
            <code>hasError</code>
            property to an invalid option.
          </li>
          <li>
            If there is one or more options with errors, be sure to update the
            <code>helpText</code> and <code>hasSingleError</code> props. Do not
            use the <code>hasError</code> prop, as this will apply the error
            styling to all of the option pills, regardless of whether the{' '}
            <code>hasError</code> property is true on that option.
          </li>
        </List>
        <br />

        <CodeSnippet
          scope={{ React: React, CreatableSelect: CreatableSelect }}
          code={creatableSelectTypeaheadSnippet}
          platform="react"
          gitHubLink="forms/creatable-select"
          id="typeahead-snippet"
          disableCodeEditing
        />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Forms / Creatable Select"
      />
    </PlatformTab>
  );
};

export default WebTab;
